<script>
import {mdiAccountOutline, mdiAccountTieOutline, mdiClipboardAccountOutline} from "@mdi/js"
import {mapGetters} from "vuex"
import {dictRoles, getRoleColor} from "@/services/mappers"

export default {
	name: "StartView",
	computed: {
		...mapGetters("User", {
			permissions: "getPermissions",
		}),
		dictRoles:() => dictRoles,
		viewSwitcher() {
			return [
				{
					role: "Employee",
					permission: "EMPLOYEE_VIEW",
					icon: mdiAccountOutline,
					title: "Продолжить как сотрудник",
					route: {name: "Employee"},
					disabled: false
				},
				{
					role: "Manager",
					permission: "MANAGER_VIEW",
					icon: mdiAccountTieOutline,
					title: "Продолжить как менеджер",
					route: {name: "Manager"},
					disabled: false
				},
				{
					role: "Timekeeper",
					permission: "TIMEKEEPER_VIEW",
					icon: mdiClipboardAccountOutline,
					title: "Продолжить как табельщик",
					route: {name: "Timekeeper"},
					disabled: false
				},
			]
		},
		viewSwitcherDisplay() {
			return this.viewSwitcher.filter(({permission, route}) =>
				this.permissions.includes(permission) && this.$route.name !== route.name
			)
		},
	},
	methods: { 
		getRoleColor, 
		logout: function () {
			this.$auth.logout()
		},
	},
}
</script>

<template>
	<v-container
		style="height: calc(100vh - 58px);"
		class="d-flex flex-column justify-space-between"
	>
		<v-row
			align="center"
			justify="center"
		>
			<v-col
				cols="12"
				class="text-center mt-15"
			>
				<v-img
					alt="VSM Vacation"
					src="@/assets/logo/logo_c.png"
					width="200"
					contain
					class="mx-auto"
				/>
				<h1 class="mt-5">
					Планирование отпусков
				</h1>
			</v-col>
		</v-row>
		<v-row
			align="center"
			justify="center"
		>
			<v-col
				v-if="viewSwitcherDisplay.length === 0"
				cols="12"
				class="text-center mt-10"
			>
				<v-alert
					type="info"
					outlined
				>
					К сожалению, в данный момент у вас нет доступа к системе
				</v-alert>
			</v-col>
			<v-col
				v-for="({route, icon, title, role, disabled}, indexViewSwitch) in viewSwitcherDisplay"
				:key="`view_switch_${indexViewSwitch}`"
				cols="12"
				md="6"
				lg="4"
				xl="3"
			>
				<v-card
					:color="getRoleColor(role)"
					:class="role === dictRoles.EMPLOYEE ? '': 'white--text'"
					:flat="role === dictRoles.EMPLOYEE"
					:outlined="role === dictRoles.EMPLOYEE"
					style="cursor: pointer"
					:disabled="disabled"
					@click="$router.push(route)"
				>
					<v-card-subtitle>
						<v-icon
							:color="role === dictRoles.EMPLOYEE ? 'black': 'white'"
							:size="60"
						>
							{{ icon }}
						</v-icon>
					</v-card-subtitle>
					<v-card-title>{{ title }}</v-card-title>
				</v-card>
			</v-col>
		</v-row>
		<v-row
			align="center"
			justify="center"
		>
			<v-col
				cols="12"
				class="text-center mt-10"
			>
				<v-btn
					depressed
					outlined
					@click="logout"
				>
					Выйти
				</v-btn>
			</v-col>
		</v-row>
	</v-container>
</template>
